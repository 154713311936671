import { render, staticRenderFns } from "./Exhibitor.vue?vue&type=template&id=3ab22e49&scoped=true"
import script from "./Exhibitor.vue?vue&type=script&lang=js"
export * from "./Exhibitor.vue?vue&type=script&lang=js"
import style0 from "./Exhibitor.vue?vue&type=style&index=0&id=3ab22e49&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab22e49",
  null
  
)

export default component.exports